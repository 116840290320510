import CheckIcon from "@mui/icons-material/Check";
import { COURSE_STATUS } from "../../constants";
import { Avatar, styled } from "@mui/material";
import { PriorityHigh } from "@mui/icons-material";

const DaysSpan = styled("span")(({ theme }) => ({
  fontSize: "1rem",
  fontWeight: "bold",
}));

const CourseRemainingDaysIcon = ({ status, days }) => {
  if (!status) return <div style={{ width: 20, height: 20 }} />;

  const renderAvatar = (bgcolor, content) => (
    <Avatar sx={{ bgcolor }}>{content}</Avatar>
  );

  if (status === COURSE_STATUS.MISSING) {
    return renderAvatar("red", <PriorityHigh />);
  } else if (status === COURSE_STATUS.EXPIRED) {
    return renderAvatar("red", <DaysSpan>{days}</DaysSpan>);
  } else if (status === COURSE_STATUS.BUFFER) {
    return renderAvatar("#ffbf00", <DaysSpan>{days}</DaysSpan>);
  } else if (status === COURSE_STATUS.NEVER_EXPIRE) {
    return renderAvatar("green", <CheckIcon />);
  } else {
    return renderAvatar("green", <DaysSpan>{days}</DaysSpan>);
  }
};

export default CourseRemainingDaysIcon;
