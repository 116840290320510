import { useEffect, useRef, useState } from "react";

import Typography from "@mui/material/Typography";
import RemoveIcon from "@mui/icons-material/HighlightOff";
import { InputAdornment, Paper } from "@mui/material";

import { getCrewOperationsCourses, getCrewUsersOverview } from "../../api/crew";

import CrewCourseOverviewTable from "../../components/CrewCourseOverviewTable";
import SearchField from "../../components/TableComponent/SearchField";
import { formatDate, formatLastFirstName } from "../../services/formatters";
import Button from "../../components/Button";

import "./index.scss";

import Box from "@mui/material/Box";
import {
  getBufferZoneStartDate,
  getRemainingValidityDays,
  status,
  validUntil,
} from "src/services/certificate";
import { COMMON, COURSE_STATUS } from "src/constants";
import SkeletonTableLoader from "src/components/SkeletonTableLoader/SkeletonTableLoader";
import { isValidDate } from "src/utils/isValidDate";

const groupByCourseCategory = (courses) => {
  return Object.values(
    _.groupBy(
      courses,
      (course) => course.courseCategory || COMMON.UNCATIGORIZED,
    ),
  );
};

const getCourses = (operations) => {
  return operations
    .flatMap((op) => [
      ...op.courses.map((course) => ({
        ...course,
        category_id: op.category_id,
        category_name: op.category_name,
      })),
      ...op.customCourses.map((customCourse) => ({
        ...customCourse,
        category_id: op.category_id,
        category_name: op.category_name,
      })),
    ])
    .reduce((acc, course) => {
      const existingCourse = acc.find((c) => c.id === course.id);
      if (existingCourse) {
        const nonExistingCategory = existingCourse.categories.some(
          (cat) => cat.category_id === course.category_id,
        );

        if (!nonExistingCategory) {
          existingCourse.categories.push({
            category_id: course.category_id,
            category_name: course.category_name,
          });
        }
      } else {
        acc.push({
          ...course,
          categories: [
            {
              category_id: course.category_id,
              category_name: course.category_name,
            },
          ],
        });
      }

      return acc;
    }, [])
    .map(({ category_id, category_name, ...rest }) => rest);
};

const mapUserCourses = (user, operations) => {
  let coursesInfos = [];
  user.assignedRoles.forEach((role) => {
    const op = operations.find((op) => role.operation_id === op.operation_id);
    op.courses.map((course) => {
      const matchCourse = user.courses.find(
        (item) => item.course_id === course.id,
      );

      const refresherCourses = user.courses.filter((cer) =>
        course.refreshers.some((refresher) => cer.course_id === refresher),
      );

      if (matchCourse) {
        refresherCourses.push(matchCourse);
      }

      if (refresherCourses.length) {
        const earliestRefresher = refresherCourses.reduce(
          (earliest, current) => {
            return new Date(current.completed_date) <
              new Date(earliest.completed_date)
              ? current
              : earliest;
          },
        );
        const { id } = course;
        const {
          user_id,
          completed_date,
          course_valid_until,
          buffer_zone,
          type,
          valid_period,
        } = earliestRefresher;

        coursesInfos.push({
          user_id,
          course_id: id,
          completed_date,
          course_valid_until,
          buffer_zone,
          type,
          valid_period,
        });
      } else coursesInfos.push(course);
    });
    op.customCourses.map((cc) => {
      const matchCC = user.customCourses.find(
        (item) => item.custom_course_id === cc.id,
      );
      if (matchCC) {
        coursesInfos.push(matchCC);
      } else {
        coursesInfos.push(cc);
      }
    });
  });
  return coursesInfos;
};
const getFormattedValidUntil = (status, validDue) => {
  if (status === COURSE_STATUS.NEVER_EXPIRE) {
    return COURSE_STATUS.NEVER_EXPIRE;
  }

  return isValidDate(validDue) ? formatDate(validDue) : validDue;
};

// Main function
const getCourseValidityInfo = (courseStatus) => {
  const currentStatus = status(courseStatus);
  const validDue = validUntil(courseStatus);
  const buffer = getBufferZoneStartDate(courseStatus);
  const days = getRemainingValidityDays(courseStatus);

  return {
    status: currentStatus,
    validUntil: getFormattedValidUntil(currentStatus, validDue),
    buffer: buffer ? formatDate(buffer) : "",
    days: days || "",
  };
};
const getUserCourseStatus = (user, coursesCategory, operations) => {
  const courseInfos = mapUserCourses(user, operations);
  let userPositions = [];
  coursesCategory.map((courses) => {
    userPositions.push(
      courses.map((course) => {
        const courseStatus = courseInfos.find(
          (item) =>
            item.id === course.id ||
            item.course_id === course.id ||
            item.custom_course_id === course.id,
        );

        if (!courseStatus) {
          return {
            status: "",
            validUntil: "",
          };
        } else if (courseStatus.id) {
          return {
            status: status(null),
            validUntil: "missing",
          };
        } else {
          return getCourseValidityInfo(courseStatus);
        }
      }),
    );
  });

  return {
    id: user.user_id,
    positions: [user.name, ...userPositions].flat(),
  };
};

const CrewCourseOverview = (params) => {
  const crewId = params.match.params.crewId;

  const [courses, setCourses] = useState([]);
  const [users, setUsers] = useState([]);
  const [noData, setNoData] = useState(false);
  const [search, setSearch] = useState("");
  const [searchCat, setSearchCat] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchOp, setSearchOp] = useState("");
  const [isLoading, setLoading] = useState(true);
  const searchRef = useRef();

  useEffect(() => {
    if (!crewId) return;
    (async () => {
      const [users, operations] = await Promise.all([
        getCrewUsersOverview(crewId),
        getCrewOperationsCourses(crewId),
      ]);

      const courses = getCourses(operations).sort((a, b) => {
        if (a.fullname < b.fullname) return -1;
        if (a.fullname > b.fullname) return 1;
        return 0;
      });
      const coursesCategory = groupByCourseCategory(courses);
      const updatedUsers = users
        .map((user) => {
          return getUserCourseStatus(user, coursesCategory, operations);
        })
        .sort(({ positions: [name1] }, { positions: [name2] }) =>
          formatLastFirstName(name1).localeCompare(formatLastFirstName(name2)),
        );

      setCourses(coursesCategory);
      setUsers(updatedUsers);
      setNoData(!courses.length);
      setLoading(false);
    })();
  }, [crewId]);

  return (
    <Box>
      <Paper className={"tools right"} elevation={5}>
        <SearchField
          placeholder="Filter users"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  icon={<RemoveIcon />}
                  onClick={() => setSearch("")}
                  title={"Clear filter"}
                />
              </InputAdornment>
            ),
          }}
        />
        <SearchField
          placeholder="Filter courses"
          value={searchOp}
          onChange={(e) => setSearchOp(e.target.value)}
          InputProps={{
            ref: searchRef,
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  icon={<RemoveIcon />}
                  onClick={() => setSearchOp("")}
                  title={"Clear filter"}
                />
              </InputAdornment>
            ),
          }}
        />
        <SearchField
          placeholder="Filter category"
          value={searchCat}
          onChange={(e) => setSearchCat(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  icon={<RemoveIcon />}
                  onClick={() => setSearchCat("")}
                  title={"Clear filter"}
                />
              </InputAdornment>
            ),
          }}
        />
      </Paper>

      <div>
        {isLoading && (
          <SkeletonTableLoader
            columns={10}
            rows={30}
            cellHeight={40}
            headerHeight={40}
          />
        )}
        {noData && <Typography variant="h4">No Data Found</Typography>}
        {courses && users && (
          <CrewCourseOverviewTable
            crewId={crewId}
            courses={courses}
            users={users.filter(
              ({ positions: [name] }) =>
                !search || name.toLowerCase().includes(search.toLowerCase()),
            )}
            onUserSelected={(user) => setSearch(search === user ? "" : user)}
            onCourseSelected={(course) =>
              setSearchOp(searchOp === course ? "" : course)
            }
            onCategorySelected={(courseCategory) => {
              setSearchText(
                searchText === courseCategory ? "" : courseCategory,
              );
            }}
            searchOp={searchOp}
            searchRef={searchRef}
            searchCat={searchCat}
            searchText={searchText}
          />
        )}
      </div>
    </Box>
  );
};

export default CrewCourseOverview;
