import React from "react";
import { Switch, Route } from "react-router";
import CrewOverview from "../../features/crew-overview";
import CrewAircraftOverview from "../../features/crew-aircraft-overview";
import CrewCourseOverview from "src/features/crew-course-overview";
import CrewSubscriptions from "../../features/crew-subscriptions";
import MembersList from "../../features/members-list";
import UsersList from "../../features/users-list";
import MembersPage from "../members-page";
import AddUser from "../../features/user-add";
import InviteUser from "../../features/user-invite";
import PendingCertificates from "../../features/pending-certificates";
import CustomCourseAdd from "../../features/custom-courses-add";
import CustomCoursesTable from "../../features/custom-courses-table";
import CrewAircraft from "../../features/crew-aircraft";
import ShoppingCart from "../../features/shopping-cart";
import MemberEdit from "../member-edit";
import UserCrewEdit from "../user-edit";

import GroupAircraft from "../group-aircraft";

import GroupAircraftAdd from "../../features/group-aircraft-add";
import GroupAircraftEdit from "../../features/group-aircraft-edit";

const CrewPage = ({ match, history }) => (
  <>
    <Switch>
      <Route path={`${match.path}/:crewId/cart`} component={ShoppingCart} />
      <Route
        path={[
          `${match.path}/:crewId/customCourses/add`,
          `${match.path}/:crewId/customCourses/edit/:courseId`,
        ]}
        component={CustomCourseAdd}
      />
      <Route
        path={`${match.path}/:crewId/customCourses`}
        component={CustomCoursesTable}
      />

      <Route
        path={`${match.path}/:crewId/pending`}
        component={PendingCertificates}
      />
      <Route
        path={`${match.path}/:crewId/subscriptions`}
        component={CrewSubscriptions}
      />
      <Route path={`${match.path}/:crewId/overview`} component={CrewOverview} />
      <Route
        path={`${match.path}/:crewId/aircraft_overview`}
        component={CrewAircraftOverview}
      />
      <Route
        path={`${match.path}/:crewId/course_overview`}
        component={CrewCourseOverview}
      />
      <Route
        path={`${match.path}/:crewId/members/invite`}
        component={InviteUser}
      />
      <Route path={`${match.path}/:crewId/members/add`} component={AddUser} />
      <Route path={`${match.path}/:crewId/users/add`} component={AddUser} />
      <Route
        path={`${match.path}/:crewId/members/:userId/edit`}
        component={MemberEdit}
      />

      <Route
        path={`${match.path}/:crewId/users/:userId/edit`}
        component={UserCrewEdit}
      />
      <Route
        path={`${match.path}/:crewId/members/:userId`}
        component={MembersPage}
      />
      <Route path={`${match.path}/:crewId/users`} component={UsersList} />
      <Route path={`${match.path}/:crewId/members`} component={MembersList} />
      <Route path={`${match.path}/:crewId/aircraft`} component={CrewAircraft} />
      <Route
        path={`${match.path}/:crewId/group-aircraft`}
        component={GroupAircraft}
      />
    </Switch>
  </>
);

export default CrewPage;
