import { useMemo } from "react";
import { useDebounce } from "use-debounce";

import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";

import { Paper, TableContainer } from "@mui/material";
import { styled } from "@mui/material/styles";

import { formatShortName } from "../../services/formatters";

import "./index.scss";
import CourseStatusTooltip from "../CourseStatusTooltip";
import { COMMON } from "src/constants";

const StickyTableCell = styled(TableCell)(({ theme }) => ({
  "&.MuiTableCell-head": {
    left: 0,
    position: "sticky",
    zIndex: theme.zIndex.appBar - 3,
    width: "200px",
    "&:first-of-type": {
      zIndex: theme.zIndex.appBar - 1,
    },
  },
  "&.MuiTableCell-body": {
    left: 0,
    backgroundColor: "white",
    position: "sticky",
    zIndex: theme.zIndex.appBar - 2,
  },
}));

const getFilteredUsers = (users, courseIndexes) => {
  const filteredUser = users.map(
    ({ id, positions: [u, ...courseStatuses] }) => {
      return {
        id: id,
        positions: [
          u,
          courseStatuses.filter((_, ix) => !courseIndexes || courseIndexes[ix]),
        ].flat(),
      };
    },
  );

  return filteredUser.filter(({ positions: [_, ...courseStatuses] }) =>
    courseStatuses.some((el) => el.status),
  );
};

const getFilteredCourseCategories = (courses, searchText) => {
  if (!searchText) return courses;

  return courses.map((cat) => {
    const isMatch =
      searchText === COMMON.UNCATIGORIZED
        ? cat[0].courseCategory === null
        : cat[0].courseCategory === searchText;

    return isMatch ? cat : cat.map(() => "");
  });
};

const applyCourseFilter = ({
  searchText,
  searchCat,
  searchOp,
  courses,
  users,
}) => {
  if (searchCat || searchOp || searchText) {
    courses = getFilteredCourseCategories(courses, searchText);

    searchCat = searchCat.toLowerCase();
    searchOp = searchOp.toLowerCase();
    courses = courses.map((cat) => {
      if (cat[0]) {
        return cat.map((course) => {
          const categoryName = course.categories.find((cat) =>
            cat.category_name.toLowerCase().includes(searchCat),
          );

          if (
            categoryName &&
            course.fullname.toLowerCase().includes(searchOp)
          ) {
            return course;
          }
          return "";
        });
      }
      return cat;
    });
    users = getFilteredUsers(users, courses.flat());

    courses = courses
      .map((cat) => cat.filter((it) => it))
      .filter((it) => it.length);
  }

  return { courses, users };
};

const CrewCourseOverviewTable = ({
  courses: courses_ = [],
  users: users_ = [],
  onUserSelected = () => {},
  onCourseSelected,
  onCategorySelected,
  searchOp: searchOpVal,
  searchRef,
  searchCat,
  searchText,
}) => {
  const [searchOp_, { cancel }] = useDebounce(searchOpVal, 400, {
    maxWait: 1500,
  });

  // use debounced value only if the search element is active and the current input value is non-empty
  // otherwise we can just update the display immediately
  let searchOp;
  if (!searchOpVal || document.activeElement !== searchRef) {
    searchOp = searchOpVal;
    // no need for a delayed update as we'll already be using the up to date value
    setTimeout(cancel, 0);
  } else {
    searchOp = searchOp_;
  }

  const { courses, users } = useMemo(
    () =>
      applyCourseFilter({
        searchText,
        searchCat,
        searchOp,
        courses: courses_,
        users: users_,
      }),
    [searchOp, courses_, users_],
  );

  return (
    <>
      <TableContainer component={Paper} variant="outlined">
        <Table stickyHeader size="small" className={"crew-table"}>
          <TableHead>
            <TableRow>
              <StickyTableCell></StickyTableCell>
              {courses.map((cat, index) => (
                <StickyTableCell
                  key={index}
                  className={"subcat"}
                  colSpan={cat.length}
                  onClick={() => {
                    onCategorySelected(
                      cat[0].courseCategory || COMMON.UNCATIGORIZED,
                    );
                  }}
                >
                  <span>{cat[0].courseCategory ?? "No Theme"}</span>
                </StickyTableCell>
              ))}
            </TableRow>
            <TableRow>
              <StickyTableCell className={"cell"} />
              {courses.map((cat) =>
                cat.map((course) => (
                  <StickyTableCell
                    key={course.id}
                    align="left"
                    className={"rotate-cell cell"}
                    onClick={() => onCourseSelected(course.fullname)}
                  >
                    <p className={"rotate"}>
                      <span className={"inner"} title={course.fullname}>
                        {course.fullname}
                      </span>
                    </p>
                  </StickyTableCell>
                )),
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id}>
                <StickyTableCell
                  style={{ whiteSpace: "nowrap" }}
                  className={"cell"}
                  title={user.positions[0]}
                  onClick={() => onUserSelected(user.positions[0])}
                >
                  {formatShortName(user.positions[0])}
                </StickyTableCell>
                {user.positions.slice(1).map((data, ix) => (
                  <TableCell
                    key={ix}
                    align="center"
                    padding="none"
                    style={{ verticalAlign: "bottom" }}
                    className={"cell"}
                  >
                    <CourseStatusTooltip data={data} />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CrewCourseOverviewTable;
