import Typography from "@mui/material/Typography";
import { useMemo, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import BookIcon from "@mui/icons-material/CollectionsBookmark";
import IconExpandLess from "@mui/icons-material/ExpandLess";
import IconExpandMore from "@mui/icons-material/ExpandMore";
import PeopleIcon from "@mui/icons-material/People";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import BoyIcon from "@mui/icons-material/Boy";
//import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import FlightIcon from "@mui/icons-material/Flight";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import SpeedIcon from "@mui/icons-material/Speed";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import { useTranslation } from "react-i18next";
import Divider from "@mui/material/Divider";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BuildIcon from "@mui/icons-material/Build";
import CheckIcon from "@mui/icons-material/CheckBox";
import ApprovalIcon from "@mui/icons-material/Approval";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import PersonIcon from "@mui/icons-material/Person";
import MenuBook from "@mui/icons-material/MenuBook";
import SchoolIcon from "@mui/icons-material/School";
import WorkIcon from "@mui/icons-material/Work";
import ContactsIcon from "@mui/icons-material/Contacts";
import AppsIcon from "@mui/icons-material/Apps";
import ArticleIcon from "@mui/icons-material/Article";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import AlignHorizontalCenterIcon from "@mui/icons-material/AlignHorizontalCenter";
import { FlightTakeoff, LocalAtm } from "@mui/icons-material";
import Side_Bar_Logo from "../../assets/icon/side-bar-logo.png";
import { styled } from "@mui/material/styles";
import useCrew from "../../hooks/useCrew";
import useUser from "../../hooks/useUser";
import { getUserRoles } from "../../api/user";
import { getCrewApps } from "../../api/crew";

import "./index.scss";
import PendingCertificatesBadge from "../pending-certificates/Badge";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";

const COLOR_DEFAULT = "#526A85";

const StyledListItemIcon = styled(ListItemIcon)({
  minWidth: "2.5rem",
});

const ListItem = ({ to, children, onClick, component, setMenuOpen }) => {
  const history = useHistory();
  const isCurrentLocation = useMemo(
    () => history.location.pathname === to,
    [history.location.pathname, to],
  );
  return (
    <ListItemButton
      component={component}
      onClick={onClick}
      classes={{ root: `list-item${isCurrentLocation ? " active" : ""}` }}
      to={to}
    >
      {children}
    </ListItemButton>
  );
};

const SubmenuItem = ({ title, to, menuOpen, setMenuOpen }) => (
  <ListItem component={Link} to={to}>
    <ListItemText
      inset
      primaryTypographyProps={{
        classes: { root: "list-item-text subitem" },
      }}
      onClick={() => setMenuOpen(false)}
    >
      {title}
    </ListItemText>
  </ListItem>
);

const WithPermission = ({ permission, crew, children }) => {
  if (
    (permission === "super_user" && crew.id === 0) ||
    crew?.permissions?.find((perm) => perm.code === permission)
  ) {
    return children;
  }
  return null;
};

const Submenu = ({
  title,
  crew,
  icon,
  items,
  expanded = false,
  menuOpen,
  setMenuOpen,
}) => {
  const [isOpened, setIsOpened] = useState(expanded);
  const history = useHistory();

  const ToggleIcon = isOpened ? IconExpandLess : IconExpandMore;

  const handleClick = () => {
    setIsOpened(!isOpened);

    const nav = items[0]?.to;
    if (nav) {
      history.push(nav);
    }
  };

  return (
    <>
      <ListItem onClick={handleClick} setMenuOpen={setMenuOpen}>
        <StyledListItemIcon>{icon}</StyledListItemIcon>
        <ListItemText
          primaryTypographyProps={{ classes: { root: "list-item-text" } }}
        >
          {title}
        </ListItemText>
        <ToggleIcon
          onClick={(e) => {
            setIsOpened(!isOpened);
            e.stopPropagation();
          }}
        />
      </ListItem>
      <Collapse in={isOpened} timeout="auto" unmountOnExit>
        <List disablePadding>
          {items.map((item, index) => {
            const el = (
              <SubmenuItem
                key={index}
                to={item.to}
                title={item.title}
                setMenuOpen={setMenuOpen}
              />
            );
            if (item.permission) {
              return (
                <WithPermission
                  key={index}
                  crew={crew}
                  permission={item.permission}
                >
                  {el}
                </WithPermission>
              );
            } else {
              return el;
            }
          })}
        </List>
      </Collapse>
    </>
  );
};

const UserSubmenu = ({ crew, menuOpen, setMenuOpen }) => {
  const { t } = useTranslation();

  const myCourses = t("my_courses");
  const myPositionCourseLabel = t("my_position_courses");
  const myOperationsCourses = t("my_operations_courses");
  const allCourses = t("all_courses");
  const allCertificates = t("all_certificates");

  //my_position_courses
  const items = [];
  if (crew.id) {
    items.push({
      title: myCourses,
      to: `/user/positions`,
    });
    /* 
    items.push({
      title: myOperationsCourses,
      to: `/user/operations`,
    });

    items.push({
      title: "My Aircraft Courses",
      to: `/user/aircraft`,
    });
    items.push({
      title: "My Specializations Courses",
      to: `/user/specializations`,
    });
    */
  }

  return (
    <Submenu
      title={t("my_courses")}
      icon={<PermContactCalendarIcon style={{ fill: COLOR_DEFAULT }} />}
      items={[
        ...items,
        {
          title: allCourses,
          to: `/user/all`,
        },
        {
          title: allCertificates,
          to: `/user/certificates`,
        },
      ]}
      setMenuOpen={setMenuOpen}
      menuOpen={menuOpen}
    />
  );
};

const UserMenu = ({
  crew,
  user,
  menuOpen,
  setMenuOpen,
  isCrewOveriewCompany,
  isSsoApps,
}) => {
  const { t } = useTranslation();
  const members = t("members");
  const inviteMembers = t("invite_members");
  const addMemebers = t("add_members");
  const completeOverview = t("complete_overview");

  return (
    <List className="menu-list">
      {isSsoApps && !isCrewOveriewCompany ? (
        <>
          <div className="app training">
            <Typography variant="h4">MANAGE USERS</Typography>
          </div>

          <WithPermission
            crew={crew}
            permission={"see_training_data_of_crew_members"}
          >
            <Submenu
              title="All Users"
              icon={<ContactsIcon style={{ fill: COLOR_DEFAULT }} />}
              crew={crew}
              items={[
                { title: "Users", to: `/crew/${crew.id}/users` },
                {
                  title: "Add Users",
                  to: `/crew/${crew.id}/users/add`,
                  permission: "create_users",
                },
              ]}
              setMenuOpen={setMenuOpen}
              menuOpen={menuOpen}
            />
            <Divider />
          </WithPermission>
        </>
      ) : null}

      {isCrewOveriewCompany && !user.ta ? (
        <>
          <div className="app training">
            <Typography variant="h4">CREW OVERVIEW MENU</Typography>
          </div>
          <UserSubmenu
            crew={crew}
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
          />
          <Divider />
          <WithPermission
            crew={crew}
            permission={"see_training_data_of_crew_members"}
          >
            <Submenu
              title={t("members")}
              icon={<PeopleIcon style={{ fill: COLOR_DEFAULT }} />}
              crew={crew}
              items={[
                { title: members, to: `/crew/${crew.id}/members` },
                {
                  title: inviteMembers,
                  to: `/crew/${crew.id}/members/invite`,
                  permission: "create_users",
                },
                {
                  title: addMemebers,
                  to: `/crew/${crew.id}/members/add`,
                  permission: "create_users",
                },
              ]}
              setMenuOpen={setMenuOpen}
              menuOpen={menuOpen}
            />
            <Divider />
          </WithPermission>
          <WithPermission
            crew={crew}
            permission={"see_training_data_of_crew_members"}
          >
            <Submenu
              title={t("members_properties")}
              icon={<PeopleIcon style={{ fill: COLOR_DEFAULT }} />}
              crew={crew}
              items={[
                { title: completeOverview, to: `/crew/${crew.id}/overview` },
                {
                  title: "Active Subscriptions",
                  to: `/crew/${crew.id}/subscriptions`,
                  permission: "buy_courses_for_crew_members",
                },

                {
                  title: "Aircraft Overview",
                  to: `/crew/${crew.id}/aircraft_overview`,
                  permission: "buy_courses_for_crew_members",
                },
                {
                  title: "Courses Overview",
                  to: `/crew/${crew.id}/course_overview`,
                },
              ]}
              setMenuOpen={setMenuOpen}
              menuOpen={menuOpen}
            />
            <Divider />
          </WithPermission>

          <WithPermission
            crew={crew}
            permission={"configure_company_custom_courses"}
          >
            <Submenu
              title={"Company Custom Courses"}
              icon={<MenuBook style={{ fill: COLOR_DEFAULT }} />}
              items={[
                {
                  title: "Company Custom Courses",
                  to: `/crew/${crew.id}/customCourses`,
                },
                {
                  title: "Add Company Custom Course",
                  to: `/crew/${crew.id}/customCourses/add`,
                },
              ]}
              setMenuOpen={setMenuOpen}
              menuOpen={menuOpen}
            />
            <Divider />
          </WithPermission>

          <WithPermission crew={crew} permission={"configure_aircrafts"}>
            <ListItem component={Link} to={`/crew/${crew.id}/aircraft`}>
              <StyledListItemIcon>
                <FlightIcon style={{ fill: COLOR_DEFAULT }} />
              </StyledListItemIcon>
              <ListItemText
                primaryTypographyProps={{ classes: { root: "list-item-text" } }}
                onClick={() => setMenuOpen(false)}
              >
                Company Aircraft
              </ListItemText>
            </ListItem>
            <Divider />
          </WithPermission>

          <WithPermission crew={crew} permission={"configure_courses"}>
            <ListItem
              component={Link}
              to={`/crew/${crew.id}/relations`}
              onClick={() => setMenuOpen(false)}
            >
              <StyledListItemIcon>
                <AccountTreeIcon style={{ fill: COLOR_DEFAULT }} />
              </StyledListItemIcon>
              <ListItemText
                primaryTypographyProps={{ classes: { root: "list-item-text" } }}
              >
                Relations Tree
              </ListItemText>
            </ListItem>
            <Divider />
          </WithPermission>

          <WithPermission
            crew={crew}
            permission={"approve_extern_certificates"}
          >
            <ListItem component={Link} to={`/crew/${crew.id}/pending`}>
              <StyledListItemIcon>
                <CheckIcon style={{ fill: COLOR_DEFAULT }} />
              </StyledListItemIcon>
              <ListItemText
                primaryTypographyProps={{ classes: { root: "list-item-text" } }}
              >
                Approve Courses <PendingCertificatesBadge />
              </ListItemText>
            </ListItem>
            <Divider />
          </WithPermission>
          <WithPermission
            crew={crew}
            permission={"configure_roles_and_permissions"}
          >
            <ListItem component={Link} to={`/crew/${crew.id}/roles`}>
              <StyledListItemIcon>
                <AccountCircleIcon style={{ fill: COLOR_DEFAULT }} />
              </StyledListItemIcon>
              <ListItemText
                primaryTypographyProps={{ classes: { root: "list-item-text" } }}
              >
                {t("roles_and_permissions")}
              </ListItemText>
            </ListItem>
            <Divider />
          </WithPermission>

          <WithPermission
            crew={crew}
            permission={"configure_roles_and_permissions"}
          >
            <ListItem component={Link} to={"/orders-crew"}>
              <StyledListItemIcon>
                <LocalAtm style={{ fill: COLOR_DEFAULT }} />
              </StyledListItemIcon>
              <ListItemText
                primaryTypographyProps={{ classes: { root: "list-item-text" } }}
              >
                {t("orders_crew")}
              </ListItemText>
            </ListItem>
            <Divider />
          </WithPermission>

          <SafetyToolsMenu crew={crew} />
        </>
      ) : null}
    </List>
  );
};

const SafetyToolsMenu = ({ crew, menuOpen, setMenuOpen }) => (
  <WithPermission crew={crew} permission={"st_administration"}>
    <Submenu
      title={"FRAT Tool"}
      icon={<FormatListBulletedIcon style={{ fill: COLOR_DEFAULT }} />}
      items={[{ title: "FRAT", to: `/crew/${crew.id}/st/frat` }]}
      menuOpen={menuOpen}
      setMenuOpen={setMenuOpen}
    />
    <Divider />
  </WithPermission>
);

const SuperAdminTrainingMenu = ({ menuOpen, setMenuOpen }) => (
  <List>
    <Submenu
      title={"Define Testing"}
      icon={<AlignHorizontalCenterIcon style={{ fill: COLOR_DEFAULT }} />}
      items={[
        {
          title: "Observable Items",
          to: "/observable",
        },
        {
          title: "Activities Training",
          to: "/activities-training",
        },
        {
          title: "Process step",
          to: "/process-step",
        },

        {
          title: "Associate Position to Process Step",
          to: "/associate-position-process-step",
        },
      ]}
      menuOpen={menuOpen}
      setMenuOpen={setMenuOpen}
    />

    <Submenu
      title={"Plan Training"}
      icon={<SchoolIcon style={{ fill: COLOR_DEFAULT }} />}
      items={[
        {
          title: "Calendar",
          to: "/overview-training-courses",
        },
        {
          title: "All trainings / Add training",
          to: "/trainings",
        },
        {
          title: "Training dependencies",
          to: "/trainings-dependencies",
        },
      ]}
      menuOpen={menuOpen}
      setMenuOpen={setMenuOpen}
    />
    <Divider />

    <Submenu
      title={"Manage Trainings"}
      icon={<ManageHistoryIcon style={{ fill: COLOR_DEFAULT }} />}
      items={[
        {
          title: "Trainings list",
          to: "/system-trainer-trainings",
        },

        /* 
        {
          title: "Users Checking Progress",
          to: "/training-users/user/all",
        },
        */

        /* 
        {
          title: "Assessor List",
          to: "/training-users/assessor",
        },
        */
      ]}
      menuOpen={menuOpen}
      setMenuOpen={setMenuOpen}
    />
    <Divider />
  </List>
);

const UserTrainingMenu = ({ crew, menuOpen, setMenuOpen }) => (
  <>
    <ListItem component={Link} to={"/user-trainings"}>
      <StyledListItemIcon>
        <BoyIcon style={{ fill: COLOR_DEFAULT }} />
      </StyledListItemIcon>
      <ListItemText
        primaryTypographyProps={{ classes: { root: "list-item-text" } }}
      >
        My Trainings
      </ListItemText>
    </ListItem>
    <Divider />
  </>
);

const CrewAdminTrainingMenu = ({ crew, menuOpen, setMenuOpen }) => (
  <>
    <Submenu
      title={"Crew Trainings"}
      icon={<AdminPanelSettingsIcon style={{ fill: COLOR_DEFAULT }} />}
      items={[
        {
          title: "Member trainings",
          to: `/crew-users-trainings/${crew.id}`,
        },
        {
          title: "Available trainings",
          to: `/crew-trainings/${crew.id}`,
        },
      ]}
      menuOpen={menuOpen}
      setMenuOpen={setMenuOpen}
    />
    <Divider />
  </>
);

const TrainerTrainingMenu = ({ crew, menuOpen, setMenuOpen }) => (
  <>
    <ListItem component={Link} to={"/trainer-trainings"}>
      <StyledListItemIcon>
        <CoPresentIcon style={{ fill: COLOR_DEFAULT }} />
      </StyledListItemIcon>
      <ListItemText
        primaryTypographyProps={{ classes: { root: "list-item-text" } }}
      >
        Trainer (classroom)
      </ListItemText>
    </ListItem>
    <Divider />
  </>
);

const InstructorTrainingMenu = ({ crew, menuOpen, setMenuOpen }) => (
  <>
    <ListItem component={Link} to={"/instructor-trainings"}>
      <StyledListItemIcon>
        <PersonSearchIcon style={{ fill: COLOR_DEFAULT }} />
      </StyledListItemIcon>
      <ListItemText
        primaryTypographyProps={{ classes: { root: "list-item-text" } }}
      >
        Instructor (practical)
      </ListItemText>
    </ListItem>

    <Divider />
  </>
);

const AssessorTrainingMenu = ({ crew, menuOpen, setMenuOpen }) => (
  <>
    <ListItem component={Link} to={"/assessor-trainings"}>
      <StyledListItemIcon>
        <PersonPinCircleIcon style={{ fill: COLOR_DEFAULT }} />
      </StyledListItemIcon>
      <ListItemText
        primaryTypographyProps={{ classes: { root: "list-item-text" } }}
      >
        Assessor
      </ListItemText>
    </ListItem>
    <Divider />
  </>
);

const CheckerTrainingMenu = ({ crew, menuOpen, setMenuOpen }) => (
  <>
    <ListItem component={Link} to={"/checker-trainings"}>
      <StyledListItemIcon>
        <ApprovalIcon style={{ fill: COLOR_DEFAULT }} />
      </StyledListItemIcon>
      <ListItemText
        primaryTypographyProps={{ classes: { root: "list-item-text" } }}
      >
        Checker
      </ListItemText>
    </ListItem>
    <Divider />
  </>
);

const SuperAdminMenu = ({ menuOpen, setMenuOpen }) => {
  const { t } = useTranslation();
  return (
    <List>
      <Submenu
        title={"Courses"}
        icon={<SchoolIcon style={{ fill: COLOR_DEFAULT }} />}
        items={[
          {
            title: "All Moodle Courses",
            to: "/courses/moodle",
          },
          {
            title: "All Custom Courses",
            to: "/courses/custom",
          },
          {
            title: "Add Custom Course",
            to: "/courses/custom/add",
          },
          {
            title: "Course Bundles",
            to: "/bundles",
          },
        ]}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
      />
      <Divider />

      <Submenu
        title={"Courses Groups"}
        icon={<AllInboxIcon style={{ fill: COLOR_DEFAULT }} />}
        items={[
          {
            title: "Groups",
            to: "/courses_groups",
          },
          {
            title: "Add group",
            to: "/courses_groups/add_courses_group",
          },
        ]}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
      />
      <Divider />

      <Submenu
        title={"NGFT APPs"}
        icon={<AppsIcon style={{ fill: COLOR_DEFAULT }} />}
        items={[
          {
            title: "NGFT APPs",
            to: "/ngft_apps",
          },
          {
            title: "Crew NGFT APPs",
            to: "/crew_ngft_apps",
          },
          {
            title: "Cockpit Permission Roles",
            to: "/cockpit_permission_roles",
          },
        ]}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
      />
      <Divider />
      <Submenu
        title={"News"}
        icon={<ArticleIcon style={{ fill: COLOR_DEFAULT }} />}
        items={[
          {
            title: "News",
            to: "/news",
          },
          {
            title: "Add news",
            to: "/news/add_news",
          },
        ]}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
      />
      <Divider />
      <Submenu
        title={"Users"}
        icon={<PersonIcon style={{ fill: COLOR_DEFAULT }} />}
        items={[
          { title: "All Users", to: `/users` },
          { title: "Subscriptions", to: `/subscriptions` },
        ]}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
      />
      <Divider />
      <Submenu
        title={"Companies"}
        icon={<PeopleIcon style={{ fill: COLOR_DEFAULT }} />}
        items={[
          { title: "All Companies", to: `/crews` },
          {
            title: "Add Company",
            to: "/crews/add_crew",
          },
        ]}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
      />
      <Divider />
      <Submenu
        title={"Job Categories"}
        icon={<BookIcon style={{ fill: COLOR_DEFAULT }} />}
        items={[
          {
            title: "Job Categories",
            to: "/categories",
          },
          {
            title: "Add Job Category",
            to: "/categories/add_category",
          },
        ]}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
      />
      <Divider />
      <Submenu
        title={"Positions"}
        icon={<WorkIcon style={{ fill: COLOR_DEFAULT }} />}
        items={[
          {
            title: "Positions",
            to: "/positions",
          },
          {
            title: "Add Position",
            to: "/positions/add_position",
          },
        ]}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
      />
      <Divider />
      <Submenu
        title={"Operations"}
        icon={<BuildIcon style={{ fill: COLOR_DEFAULT }} />}
        items={[
          {
            title: "Operations",
            to: "/operations",
          },
          {
            title: "Add Operation",
            to: "/operations/add_operation",
          },
        ]}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
      />
      <Divider />

      <Submenu
        title={"Aircraft"}
        icon={<FlightIcon style={{ fill: COLOR_DEFAULT }} />}
        items={[
          {
            title: "Aircraft",
            to: "/aircraft",
          },
          {
            title: "Add Aircraft",
            to: "/aircraft/add_aircraft",
          },
        ]}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
      />
      <Divider />
      <Submenu
        title={"Specializations"}
        icon={<SpeedIcon style={{ fill: COLOR_DEFAULT }} />}
        items={[
          {
            title: "Specializations",
            to: "/specializations",
          },
          {
            title: "Add Specialization",
            to: "/specializations/add_specialization",
          },
        ]}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
      />
      <Divider />
      <ListItem component={Link} to={"/relations"}>
        <StyledListItemIcon>
          <AccountTreeIcon style={{ fill: COLOR_DEFAULT }} />
        </StyledListItemIcon>
        <ListItemText
          primaryTypographyProps={{ classes: { root: "list-item-text" } }}
          onClick={() => setMenuOpen(false)}
        >
          Relations Tree
        </ListItemText>
      </ListItem>
      <Divider />
      <ListItem component={Link} to={"/roles"}>
        <StyledListItemIcon>
          <AccountCircleIcon style={{ fill: COLOR_DEFAULT }} />
        </StyledListItemIcon>
        <ListItemText
          primaryTypographyProps={{ classes: { root: "list-item-text" } }}
        >
          {t("roles_and_permissions")}
        </ListItemText>
      </ListItem>
      <Divider />
      <Submenu
        title={"Orders"}
        icon={<LocalAtm style={{ fill: COLOR_DEFAULT }} />}
        items={[
          {
            title: "Orders",
            to: "/orders",
          },
          {
            title: "Transactions Bank",
            to: "/transactions-bank",
          },
          {
            title: "Upload Bank file",
            to: "/upload-bank-file",
          },
        ]}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
      />
      <Divider />
    </List>
  );
};

// new sidebar to use delete the old sidebar above
const Sidebar = ({ menuOpen, setMenuOpen }) => {
  const { crew } = useCrew();
  const user = useUser();
  const [refresh, setRefresh] = useState(1);
  const userCrews = user.crews.filter((el) => el.id === crew.id)[0];
  const [userRoles, setUserRoles] = useState(null);
  const [isTrainier, setIsTrainier] = useState(false);
  const [isIntructors, setIsIntructors] = useState(false);
  const [isAssessors, setAssessors] = useState(false);
  const [isCheckers, setIsCheckers] = useState(false);
  const [isCrewAdmin, setCrewAdmin] = useState(false);
  const [isCrewOveriewCompany, setCrewOveriewCompany] = useState(false);
  const [isSsoApps, setSsoApps] = useState(false);
  //const [ isTrainingAdmin, setIsTrainingAdmin] = useState(false);

  useEffect(() => {
    (async () => {
      const crewApps = await getCrewApps(crew.id);
      const userRoles = await getUserRoles(user.id);
      setUserRoles(userRoles);

      //setIsTrainingAdmin(user.ta)

      // check if the company has Crew Overview enabled
      const crewAppsHasCrewOverview = crewApps.some((app) => app.app_id === 1);
      setCrewOveriewCompany(crewAppsHasCrewOverview);

      // check if the company has Apps SSO enabled (audit tool = 3, frat tool = 20, ngft-ai = 22)
      const crewAppsHasSSO = crewApps.some(
        (app) => app.app_id === 3 || app.app_id === 20 || app.app_id === 22,
      );
      setSsoApps(crewAppsHasSSO);

      const crewAdminState = userRoles
        .filter((elem) => elem.id === crew.id)
        .map((item) =>
          item.roles.some(
            (el) =>
              el.name === "Nominated Person Crew Training" ||
              el.name === "Nominated Person Ground Operation",
          ),
        );
      setCrewAdmin(crewAdminState.includes(true) || crew.id === 0);

      const trainerState = userRoles
        .filter((elem) => elem.id === crew.id)
        .map((item) => item.roles.some((el) => el.name === "Trainer"));
      setIsTrainier(trainerState.includes(true) || crew.id === 0);

      const instructorState = userRoles
        .filter((elem) => elem.id === crew.id)
        .map((item) => item.roles.some((el) => el.name === "Instructor"));
      setIsIntructors(instructorState.includes(true) || crew.id === 0);

      const assessorState = userRoles
        .filter((elem) => elem.id === crew.id)
        .map((item) => item.roles.some((el) => el.name === "Assessor"));
      setAssessors(assessorState.includes(true) || crew.id === 0);

      const checkerState = userRoles
        .filter((elem) => elem.id === crew.id)
        .map((item) => item.roles.some((el) => el.name === "Checker"));
      setIsCheckers(checkerState.includes(true) || crew.id === 0);

      const aiManagerState = userRoles
        .filter((elem) => elem.id === crew.id)
        .map((item) => item.roles.some((el) => el.code === "ai_manager"));
      //setIsCheckers(checkerState.includes(true) ||  crew.id === 0 )
      const aiUserState = userRoles
        .filter((elem) => elem.id === crew.id)
        .map((item) => item.roles.some((el) => el.code === "ai_user"));
    })();
  }, [user, crew]);

  return (
    <div className="side-bar">
      <div className="logo-wrapper">
        <div>
          <Link to="/" onClick={() => setMenuOpen(false)}>
            <img src={Side_Bar_Logo} alt="logo" />
          </Link>
        </div>
        <div className="close-icon" onClick={() => setMenuOpen(false)}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
              stroke="#fff"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.16998 14.83L14.83 9.17004"
              stroke="#fff"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14.83 14.83L9.16998 9.17004"
              stroke="#fff"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>

      <hr className="menu-divider" />
      {crew.id !== 0 ? (
        <UserMenu
          crew={crew}
          user={user}
          isCrewOveriewCompany={isCrewOveriewCompany}
          isSsoApps={isSsoApps}
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
        />
      ) : (
        <SuperAdminMenu
          crew={crew}
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
        />
      )}

      {(crew.id == 0 || user.ta) && (
        <div className="app training">
          <Typography variant="h4">ADMIN TRAINING</Typography>
        </div>
      )}
      {crew.id == 0 || user.ta ? (
        <SuperAdminTrainingMenu
          crew={crew}
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
        />
      ) : (
        ""
      )}

      {isCrewOveriewCompany ? (
        <>
          <div>
            <Typography variant="h4">DG/CRM CREW TRAINING</Typography>
          </div>
          <UserTrainingMenu
            crew={crew}
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
          />

          {isCrewAdmin || user.sa ? (
            <CrewAdminTrainingMenu
              crew={crew}
              menuOpen={menuOpen}
              setMenuOpen={setMenuOpen}
            />
          ) : (
            ""
          )}

          {isTrainier ? (
            <TrainerTrainingMenu
              crew={crew}
              menuOpen={menuOpen}
              setMenuOpen={setMenuOpen}
            />
          ) : (
            ""
          )}

          {isIntructors ? (
            <InstructorTrainingMenu
              crew={crew}
              menuOpen={menuOpen}
              setMenuOpen={setMenuOpen}
            />
          ) : (
            ""
          )}

          {isAssessors ? (
            <AssessorTrainingMenu
              crew={crew}
              menuOpen={menuOpen}
              setMenuOpen={setMenuOpen}
            />
          ) : (
            ""
          )}

          {isCheckers ? (
            <CheckerTrainingMenu
              crew={crew}
              menuOpen={menuOpen}
              setMenuOpen={setMenuOpen}
            />
          ) : (
            ""
          )}
        </>
      ) : null}

      <div className="app links">
        <ListItem
          component={Link}
          to=""
          onClick={() => window.open(`/`, "_self")}
        >
          <StyledListItemIcon>
            <FlightTakeoff style={{ fill: COLOR_DEFAULT }} />
          </StyledListItemIcon>
          <ListItemText
            primaryTypographyProps={{ classes: { root: "list-item-text" } }}
            onClick={() => setMenuOpen(false)}
          >
            Cockpit
          </ListItemText>
        </ListItem>
      </div>
    </div>
  );
};

export default Sidebar;
